@use '@nationwide-bolt/css-core/dist/scss/tokens' as Bolt;
.modal-content {
  border-radius: 15px 15px 0 0 !important;
}

.modal-header {
  background-color: Bolt.$palette-vibrantBlue;
  color: #fff !important;
  border-radius: 12px 12px 0 0 !important;
  font-weight: 500;
  font-family: 'Gotham';
  align-items: center;
  padding-top: 1.5rem !important;
  padding-left: 2rem !important;
  margin-bottom: 10px;
  & span.modal-header-title {
    font-size: 1.1rem;
  }
  & span.modal-header-label {
    font-family: 'Gotham Light';
    font-weight: 400;
    font-size: 1 rem;
    line-height: 25px;
  }
}

.modal-body {
  padding: 1rem 2rem !important;
}

.modal-footer {
  display: unset !important;
  padding: 0 !important;
  border-top: 0 !important;
  & > * {
    margin: 0 !important;
    padding: 0.25rem !important;
  }
}

.credit-notice {
  & .modal-content {
    border-radius: 0.25rem !important;
  }
  & .modal-header {
    background-color: inherit;
    color: #444 !important;
    font-size: 1.5rem;
    padding: 1.5rem !important;
    font-weight: 500;
  }
  & .modal-body {
    padding: 1.5rem !important;
  }
  & .modal-footer {
    padding: 1.5rem !important;
    border-top: 1px solid #dee2e6 !important;
  }
}
