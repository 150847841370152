@use '@nationwide-bolt/css-core/dist/scss/tokens' as Bolt;

.tabrow {
  position: relative;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 1rem;
  padding: 0;
  line-height: 24px;
}

.tabrow li {
  position: relative;
  width: 172px;
  margin: 0px 0px 19px 0px;
  border: none;
  display: inline-block;
  z-index: 0;
  font-family: 'Gotham Light';
  color: black;
  font-size: 22px;
  text-indent: 0;
}

.tabrow:after {
  position: absolute;
  content: '';
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: none;
}

.tabrow li a:link {
  text-decoration: none;
  border-bottom: none;
  color: black;
}

.tabrow li a:visited {
  // text-decoration: none;
  border-bottom: none;
  color: black;
  outline: none;
  box-shadow: none;
}

//TODO: keep outline
.tabrow li a:focus {
  // text-decoration: none;
  border-bottom: none;
  color: black;
  // box-shadow: none;
}

.tabrow li a:hover {
  // text-decoration: none;
  border-bottom: none;
  color: black;
}

.tabrow li a:active {
  // text-decoration: none;
  border-bottom: none;
  color: black;
  // outline: none;
  box-shadow: none;
}

.tabrow li {
  &.selected {
    font-weight: 800;
    &:nth-of-type(1).selected ~ .selectbar {
      left: 0;
      width: 140px;
      z-index: 1;
      transition: 0.5s ease;
      -webkit-transition: 0.5s ease;
    }
    &:nth-of-type(2).selected ~ .selectbar {
      left: 175px;
      width: 170px;
      z-index: 1;
      transition: 0.5s ease;
      -webkit-transition: 0.5s ease;
    }
  }
}

.selectbar {
  width: 0;
  height: 8px;
  background: Bolt.$palette-vibrantBlue;
  top: 51px;
  position: absolute;
  // opacity: 0.75;

  &.new-quote {
    left: 4px;
    width: 140px;
    z-index: 1;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
  }

  &.retrieve {
    left: 183px;
    width: 170px;
    z-index: 1;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
  }
}

.tabrow li {
  &:hover {
    opacity: inherit;
  }
  cursor: pointer;
}
@media screen and (max-width: 575.98px) {
  .tabrow li {
    width: 140px;
    font-size: 18px;
  }
  .selectbar {
    &.new-quote {
      width: 120px;
    }
    &.retrieve {
      left: 150px;
      width: 150px;
    }
  }
}

bolt-tablabel {
  &:defined:not([aria-selected=true]) {
    color: black;
    text-decoration: none !important;
  }
}

bolt-tabset.button-tabs {
  bolt-tablabel {
    &[aria-selected=true] {
      color: white;
      background-color: Bolt.$palette-vibrantBlue !important;
    }

    &:defined[aria-selected=true]::before {
      content: none !important;
    }

    &:defined[aria-selected=true]::after {
      content: none !important;
    }

    padding: 10px 12px;
    margin: 0px 4px 8px 4px;

    border: 2px solid Bolt.$palette-vibrantBlue;
    border-radius: 12px;

    font-size: 14px;
    line-height: 14px;

    opacity: 1;

    &:hover {
      opacity: 0.65;
    }
    &[aria-selected='false'] {
      background: white;
      color: Bolt.$palette-vibrantBlue;
    }
  }
  bolt-tabpanel {
    background-color: Bolt.$palette-paleGray25;
    border-radius: 12px;
  }
}
