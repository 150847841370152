@use '@nationwide-bolt/css-core/dist/scss/tokens' as Bolt;
.custom-picklist-drpdwn .c-btn {
  display: inline-block !important;
  color: #222 !important;
  font-family: 'nw-primary', 'proxima-nova', 'Helvetica Neue', 'Helvetica',
    sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.125rem !important;
  margin: 0 !important;
  padding: 0.75rem !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #6c6c6c !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: Bolt.$palette-paleGray25 !important;
  transition: background-color 350ms, border 350ms, color 350ms,
    box-shadow 350ms !important;
}
.custom-picklist-drpdwn.has-error .c-btn {
  border-bottom: 2px solid #eb0004 !important;
}
.custom-picklist-drpdwn .c-btn:hover {
  background: #d8d8d8 !important;
}
.custom-picklist-drpdwn .c-angle-down,
.custom-picklist-drpdwn .c-angle-up {
  width: 18px !important;
  height: 18px !important;
}
.custom-picklist-drpdwn .c-remove.clear-all {
  padding-right: 10px !important;
  width: 18px;
}
.custom-picklist-drpdwn.is-invalid .c-btn {
  border-bottom: 2px solid #eb0004 !important;
}
.custom-picklist-drpdwn .selected-list .c-list {
  padding-right: 30px !important;
  & .c-token {
    margin-top: 5px !important;
    background: #0b3a5d !important;
    font-size: 16px;
  }
}
.custom-picklist-drpdwn .countplaceholder {
  right: 55px !important;
}
.custom-picklist-drpdwn .dropdown-list label {
  font-weight: 400 !important;
  font-family: 'Gotham Book' !important;
}
.custom-picklist-drpdwn .dropdown-list li {
  margin-bottom: inherit;
  list-style-position: inside;
  text-indent: inherit;
  padding-left: 10px;
  background-color: Bolt.$palette-paleGray25;
  border-bottom: 1px solid #d0d3d4;
  padding-bottom: 0px;
}
.custom-picklist-drpdwn .list-filter {
  & .c-search,
  .c-clear {
    top: 0px;
  }
}
.custom-picklist-drpdwn .arrow-down.arrow-2,
.custom-picklist-drpdwn .arrow-down,
.custom-picklist-drpdwn .arrow-2,
.custom-picklist-drpdwn .arrow-down,
.arrow-up {
  display: none;
}
.custom-picklist-drpdwn .dropdown-list {
  margin-bottom: -14px !important;
  margin-top: -14px !important;
}
.custom-picklist-drpdwn .dropdown-list ul li:hover,
.custom-picklist-drpdwn .selected-item {
  color: #fff;
  background-color: #0072cf !important;
  font-weight: 500;
}

.custom-label {
  color: #222;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.3rem;
  font-family: 'Gotham';
}
