@use '@nationwide-bolt/css-core/dist/scss/tokens' as Bolt;

/*Sass variables, like all Sass identifiers, treat hyphens and underscores as identical. 
This means that $font-size and $font_size both refer to the same variable. 
This is a historical holdover from the very early days of Sass, when it only allowed underscores in identifier names. 
Once Sass added support for hyphens to match CSS’s syntax, the two were made equivalent to make migration easier.*/

$standard-box-shadow: 0px 24px 24px -30px rgba(20, 27, 77, 0.5),
  0px 24px 32px 0px rgba(20, 27, 77, 0.06);

$standard-webkit-box-shadow: 0px 24px 24px -30px rgba(20, 27, 77, 0.5),
  0px 24px 32px 0px rgba(20, 27, 77, 0.06);
$bolt-style-input-hover: #e2f1fa;
$bolt-style-input-focus-box-shadow: 0 0 0 1px white,
  0 0 0 4px Bolt.$palette-mediumBlue;
$standard-radius: 12px;
$outline-border: 1px solid Bolt.$theme-outline;
$number-block-width: 46px;
