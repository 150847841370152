@use '@nationwide-bolt/css-core/dist/scss/tokens' as Bolt;
@use './scss/variables' as NwxVariables;
@import '@nationwide-bolt/css-core/dist/css/bolt-core';
@import './scss/custom-tabs';
@import './scss/custom-modal';
@import './scss/custom-picklist';

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham-Medium.otf');
  /* IE9 Compat Modes */
  src: url('/assets/fonts/gotham/Gotham-Medium.otf?#iefix') format('opentype');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('./assets/fonts/gotham-book/gotham-book.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-book/gotham-book.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-book/gotham-book.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-book/gotham-book.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-book/gotham-book.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-book/gotham-book.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-book/gotham-book.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-bold/gotham-bold.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-bold/gotham-bold.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-bold/gotham-bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-bold/gotham-bold.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-bold/gotham-bold.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('./assets/fonts/gotham-light/gotham-light.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-light/gotham-light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-light/gotham-light.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-light/gotham-light.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-light/gotham-light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-light/gotham-light.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-light/gotham-light.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Book';
  src: url('./assets/fonts/gotham-book/gotham-book.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-book/gotham-book.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-book/gotham-book.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-book/gotham-book.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-book/gotham-book.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-book/gotham-book.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-book/gotham-book.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-bold/gotham-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-bold/gotham-bold.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-bold/gotham-bold.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-bold/gotham-bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-bold/gotham-bold.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-bold/gotham-bold.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('./assets/fonts/gotham-light/gotham-light.eot');
  /* IE9 Compat Modes */
  src: url('./assets/fonts/gotham-light/gotham-light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/gotham-light/gotham-light.otf')
      format('opentype'),
    /* Open Type Font */ url('./assets/fonts/gotham-light/gotham-light.svg')
      format('svg'),
    /* Legacy iOS */ url('./assets/fonts/gotham-light/gotham-light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/gotham-light/gotham-light.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/gotham-light/gotham-light.woff2')
      format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-padding-bottom: 80px;
}

body {
  background-color: Bolt.$palette-paleGray25;
}

.base-container {
  // padding: 0 Bolt.$space-2xl
  padding: 0;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: none;
  justify-self: center;
  background-repeat: no-repeat;

  &.box-arrow-right {
    margin-left: 1rem;
    vertical-align: middle;
    background-image: url('./assets/images/icons/box-arrow-right.svg');
  }
}

/* Temporary bolt instructional text for selects */
.select-instructional-text {
  font-family: nw-primary, Gotham, 'HCo Gotham', proxima-nova, Arial, sans-serif;
  font-weight: 400;
  color: var(--bolt-foreground-color, #171717);
  font-size: var(--bolt-instructionalText-fontSize);
  letter-spacing: var(--bolt-instructionalText-letterSpacing);
  line-height: var(--bolt-instructionalText-lineHeight);
  margin-bottom: 1rem;
  margin-top: -12px;
}

/* Global Bolt tweaks.
 */
bolt-tabpanel {
  padding-left: 0px !important;
}

bolt-textfield {
  margin-bottom: 1em;
}

bolt-select {
  margin-bottom: 1em;
}

bolt-checkbox-group {
  margin-bottom: 1em;
}

bolt-radio-group {
  margin-bottom: 1em;
}

bolt-button.home-build-quote-btn {
  & button {
    min-height: 60px !important;
    line-height: inherit !important;
  }
}

/* For SelectedCardComponent header -- Let's find a nicer way to style that button. */
bolt-button.nwx-borderless-bolt-button button {
  border: 0 !important;
}

a.no-bottom-border {
  border-bottom: none;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid Bolt.$palette-vibrantBlue;
  }
}

.nwx-floating {
  background: white;
  padding: 30px;
  border-radius: 1rem;
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%);
}

a.nwx-tile > div {
  & div > bolt-checkbox {
    & > bolt-field-error {
      display: none;
    }
    & > label > svg {
      position: relative !important;
    }
  }
  & div > bolt-radio {
    & .bolt-radio-wc--label.bolt-radio svg.bolt-radio {
      margin-right: 0 !important;
    }
  }
}

.task-passive-highlight {
  /*TODO*/
  background-color: #ffff00;
}

.nowrap {
  white-space: nowrap;
}
.custom-bolt-tab {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding-top: 0px;
}

.no-edge-padding {
  padding-left: 0;
  padding-right: 0;
}
.headline {
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  color: white;
  font-family: 'Gotham Book';
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.bg-pale {
  background-color: Bolt.$palette-paleGray25;
}

.bg-pale-gray {
  background-color: Bolt.$palette-paleGray25;
}
.bg-dark-blue {
  background-color: Bolt.$palette-darkBlue;
}
.bg-vibrant-blue {
  background-color: Bolt.$palette-vibrantBlue;
}

.bolt-modal-backdrop.modal-backdrop.show {
  background: rgba(0, 0, 0);
  opacity: 0.8;
}

div[class*='icon-align-']
  bolt-contextual-help
  .bolt-icon-wc--size-md.bolt-icon {
  width: 20px !important;
  height: 20px !important;
}

// Typeahead CSS
ngb-typeahead-window {
  width: 100%;
  background-color: Bolt.$palette-paleGray25 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;

  & .dropdown-item {
    white-space: pre-wrap;
    color: #171717;
    font-weight: 700;
    font-size: 18px;
    // font-family: 'Gotham';
    padding: 8px 20px 8px 12px;
    &:not(:last-child) {
      border-bottom: 1px solid #d0d3d4;
    }
    &.active {
      background-color: #0072cf !important;
    }
  }

  & button:not(.active) > ngb-highlight > .ngb-highlight {
    color: #515155 !important;
  }
  & button.active > ngb-highlight > .ngb-highlight {
    color: #fff !important;
    font-weight: 800;
  }
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  -webkit-box-shadow: 0px 24px 24px -30px rgba(20, 27, 77, 0.52),
    0px 24px 32px 0px rgba(20, 27, 77, 0.32);
  box-shadow: 0px -24px 52px -32px rgba(20, 27, 77, 0.52),
    0px -24px 52px -32px rgba(20, 27, 77, 0.32);
  z-index: 1;
  width: 100%;
  // height: 72px;
  div.bolt-button-bar-wc {
    max-width: 1228px !important;
    padding-left: 0px;
    padding-right: 0px !important;
    margin-left: 0px;
    margin-right: 0px;
  }
  div.bolt-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    max-width: 1204px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 1228px !important;
  }
  &.force-margin {
    margin-top: 20px;
  }
}

.simple-confirm-modal-window {
  z-index: 1061;
}

.simple-confirm-modal-backdrop {
  // Need to apply important because one inlie css values comves for this component from the Modal
  // And we need to overwrite that.
  z-index: 1060 !important;
}

.sticky-footer-container {
  position: sticky;
  bottom: 0;
  -webkit-box-shadow: 0px 24px 24px -30px rgba(20, 27, 77, 0.52),
    0px 24px 32px 0px rgba(20, 27, 77, 0.32);
  box-shadow: 0px -24px 52px -32px rgba(20, 27, 77, 0.52),
    0px -24px 52px -32px rgba(20, 27, 77, 0.32);
  z-index: 1;
  width: 100%;
  height: 89px;
  padding: 20px;
}
:host(div.bolt-button-bar-wc .container-fluid) {
  width: 1228px;
}

.float-right {
  float: right;
}

.icon-align-1 {
  margin-top: 1rem;
  padding-left: 0;
  &.error {
    margin-top: -1rem !important;
  }
}
.icon-align-2 {
  margin-top: 1.5rem;
  padding-left: 0;
}
.icon-align-3 {
  padding-left: 0;
}
.no-pad {
  padding: 0px;
}
.no-pad-xs {
  @media screen and (max-width: 767.98px) {
    padding: 0px;
  }
}
.pr-xs {
  padding-right: 0.5rem;
}
.pr-md {
  padding-right: 16px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.pr-32 {
  padding-right: 32px;
}
.pt-52 {
  padding-top: 52px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-20 {
  padding-top: 20px;
}
.pad-top-5 {
  padding-top: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pt-12 {
  padding-top: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-32 {
  padding-bottom: 32px;
}
.no-mb {
  margin-bottom: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-10-xs {
  @media screen and (max-width: 576px) {
    margin-bottom: 10px;
  }
}

.ml--52 {
  margin-left: -52px;
}

.ml--22 {
  margin-left: -22px;
}

.ml--16 {
  margin-left: -16px;
}

.mr-44 {
  margin-right: 44px;
}

.page-wrapper {
  max-width: 1204px;
}

.page-wrapper-lg {
  max-width: 1500px;
  margin: auto;
}

.page-padding-container {
  padding-left: 2.5%;
  max-width: 1204px;
}

// use for pages where view may extend
// past traditional max-width, such as policy members and vehicles
.page-padding-container-lg {
  padding-right: 24px;
  padding-left: 2.5%;
}

.page-padding-lg-maxwidth {
  padding-right: 24px;
  padding-left: 2.5%;
  max-width: 1252px;
}

.vehicle-page-spacing {
  margin-left: 24px;
  margin-right: 24px;
  max-width: 1204px;
}

.faux-bolt-label {
  color: #222;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 700;
}

.faux-bolt-help-text {
  border: 0;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: baseline;
}

.faux-bolt-error {
  margin-top: 0.5rem;
  color: #222323;
  font-size: 1rem;
}

.faux-bolt-error-icon {
  vertical-align: sub;
  margin-right: 0.25rem;
}

.error-text {
  font-style: italic;
  font-size: 18px;
}

.form-container {
  padding: 16px 20px 20px 52px;
}
.card-padding {
  padding: 20px 16px 20px 36px;
}
.full-w-form-hr {
  margin-left: -52px;
  width: calc(100% + 72px);
}
.no-help-padding-r,
.no-help {
  padding-right: 46px;
}

.header-cards-container,
.form-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 20px;

  .const-card-w {
    width: 436px; // policy member and vehicle form cards get a hard coded width
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  &.center {
    align-items: center;
  }
}
.flex-child {
  flex: 1;
}

nwx-datepicker-input .ngb-dp-months .ngb-dp-month {
  width: 50%;
}

.property-form-section {
  margin: 16px 33px 16px 20px;
}

hr.property-divider {
  margin-top: 0;
  margin-bottom: 0;
}

.dashed-border {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D0D3D4FF' stroke-width='3' stroke-dasharray='10%2c 10' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.status-indicator {
  font-family: 'Gotham Book';
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 20px;
}

p.status-indicator {
  margin-bottom: 0;
}

.readonly {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: Bolt.$palette-paleGray25;
  padding-left: 0.75rem;
  border-radius: 8px;
  min-height: 2.75rem;
  cursor: not-allowed !important;
  color: #222;
}

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.centered-container {
  max-width: 1500px;
  margin: auto;
}

.container {
  margin: 0;
}

.contextual-help-box {
  height: 46px;
  width: 46px;
  text-align: center;
}

.highlight-block {
  border: 2px solid orangered;
}
.inline-icon {
  margin-right: 11px;
}

.mb-6 {
  margin-bottom: 6px;
}

.outer {
  cursor: default;
}
.page-title {
  padding-top: 3rem;
}

.button-icon {
  margin-right: 5px;
  margin-left: 5px;
}

// note: button icons will need to be added manually
.faux-bolt-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  background-color: Bolt.$palette-vibrantBlue;
  color: white;
  border-radius: 4px;
  border: none;
  font-family: 'Gotham Book';
  line-height: 18px;
  font-size: 18px;
  transition: all 350ms ease 0s;

  &.standard-blue {
    background-color: Bolt.$palette-vibrantBlue;
  }
  &.recalculate {
    background-color: #ff9800;
    color: black;
    bolt-icon {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &.recalculate:hover {
    background-color: #e88c02;
  }
}

// page wrappers
.pni-page {
  padding: 0px 24px;
}

.no-margin {
  margin: 0px;
}

.card-body-row {
  margin-top: 2rem;
}

.header-margin-bottom {
  margin-bottom: 2rem;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

::ng-deep ngb-modal-backdrop {
  z-index: 1050 !important;
}

ngb-datepicker.dropdown-menu.show {
  width: min-content;
}

.flatpickr-calendar {
  // Not ideal but needs to be !important to be in front of ngbmodal
  // 99999 is the default value in bolt's z-index style
  z-index: 99999 !important;
}

.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.responsive-nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
}

.pale-gray-card {
  background-color: Bolt.$palette-paleGray25;
  padding: 20px;
  border-radius: 12px;
  line-height: 44px;
  border: 1px solid #eaeaea;
}

.immutable-label {
  color: #222;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.readonly-label {
  margin-top: -0.25rem;
  margin-bottom: 1rem;
}

.number-block {
  background: Bolt.$palette-darkBlue;
  color: Bolt.$palette-white;
  font-family: 'Gotham';
  font-size: large;
  border-radius: NwxVariables.$standard-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: NwxVariables.$number-block-width;
  max-height: NwxVariables.$number-block-width;
  min-width: NwxVariables.$number-block-width;
  min-height: NwxVariables.$number-block-width;
  margin-right: 12px;
}

.visually-hidden {
  display: none;
}

.button-bar-width-limiter {
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  bolt-button-bar {
    max-width: 1252px;
    border: 0;
  }
}

.vertical-align-sub {
  vertical-align: sub;
}

.product-icon {
  margin-right: 8px;
  width: 44px;
  height: 44px;
  vertical-align: bottom;
}

.gap-80 {
  gap: 0px 80px;
}

.bolt-well {
  background-color: Bolt.$palette-paleGray25;
  border: 2px solid #eaeaea;
  border-radius: 12px;
  padding: 12px 16px 20px 14px;
}

.card-header-title {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 31px;
  color: white;
}

.standard-rounded-border {
  border-radius: NwxVariables.$standard-radius;
  border: NwxVariables.$outline-border;
}

.break-word {
  word-wrap: break-word;
}
.inline {
  display: inline-flex;
}
.font-weight-med {
  font-weight: 500;
}

.bolt-date-picker-wc--legend-menu-icon .date-svg .shape {
  fill: var(--bolt-palette-mint);
}

.flatpickr-day.important-date .date-svg .shape {
  fill: var(--bolt-palette-mint);
}

@media screen and (max-width: 1209px) {
  .no-help-padding-r-sm {
    padding-right: 46px;
  }
}

@media screen and (max-width: 767.98px) {
  .no-help-padding-r-xs {
    padding-right: 46px;
  }
}

@media screen and (min-width: 576px) {
  .wrapper-container {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }
}
@media screen and (max-width: 575.98px) {
  .header-cards-container,
  .form-cards-container {
    .form-card {
      width: 100%;
    }
    .const-card-w {
      width: 100%;
    }
  }
  .wrapper-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .full-w-form-hr {
    margin-left: -52px;
    width: calc(100% + 72px);
  }
  .responsive-nav-header {
    padding-top: 12px;
  }
  .form-container {
    padding: 16px 20px 20px 32px;
  }
  ngb-datepicker.dropdown-menu.show {
    width: 100%;
  }
}

@media screen and (max-width: 375.98px) {
  .card-padding {
    padding: 20px 16px 20px 16px;
  }
  .no-help-padding-r {
    padding-right: 0px;
  }
  .full-w-form-hr {
    margin-left: -32px;
    width: calc(100% + 64px);
  }
  .no-help {
    padding-right: 0px;
  }
}

@media (min-width: 576px) {
  .modal-dialog.modal-dialog-centered.modal-lg {
    max-width: calc(min(90vw, 1100px));
  }
}
// EOF, add non-responsive styling above @media queries
